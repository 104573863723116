import $ from 'jQuery';
import isMobile from './Util/detectMobile.js';
import {
	getCurrentOptions
} from './config/appConfig.js';
import classMap from './config/codeClassMap.js';
import * as tipHandlers from './eventHandler.js';
import * as oreHandlers from './eventHandle-ore';

import isHanjaZoomCase from './logic/is-hanja-zoom-case.js';
import {
	sendCloseNclick1
} from './logic/tip-nclick.js';
const tipHook = classMap['tipHook'];
const raws = classMap['rawHook'];
let previousDeviceOrientation = 'vertical';

function _eventAttachMobile($targetArray) {
	//console.log(`[_eventAttachMobile.js] _eventAttachMobile`);
	//mobile 이므로 mouse 관련 이벤트 없다. 오직 click 이벤트

	//attaching event for raws
	$targetArray.on('click', raws, $.proxy(oreHandlers.mouseover, null));

	if (isHanjaZoomCase()) {
		//console.log(`[_eventAttachMobile.js] isHanjaZoomCase = true`);
		// 모바일지식백과, 한자사전
		$targetArray.on('click', "[data-lang='hj']", $.proxy(tipHandlers.hanjaChunkClick, null));
		$targetArray.on('click', `[data-lang!='hj']${tipHook}`, $.proxy(tipHandlers.mobileClick, null));
	} else {
		//console.log(`[_eventAttachMobile.js] isHanjaZoomCase = false`);
		$targetArray.on('click', tipHook, $.proxy(tipHandlers.mobileClick, null));
	}

	// 모바일 경우 회전 발생시 닫는다.
	//$(window).on('orientationchange resize', $.proxy(tipHandlers.closeLayer, null));

	if ("onorientationchange" in window) {
		//console.log(`[eventAttach.js] _eventAttachMobile, window.orientationchange available`);
		$(window).on('orientationchange', function(event) {
			//let clientWidth = document.documentElement.clientWidth;//viewport width
			//let clientHeight = document.documentElement.clientHeight;//viewport height
			//console.log(`[eventAttach.js] _eventAttachMobile, on orientationchange event, clientWidth = ${clientWidth}, clientHeight = ${clientHeight}`);
			tipHandlers.closeLayer(event);
		});
	} else {
		//console.log(`[eventAttach.js] _eventAttachMobile, window.orientationchange not available`);
		let clientWidth = document.documentElement.clientWidth; //viewport width
		let clientHeight = document.documentElement.clientHeight; //viewport height
		if (clientWidth > clientHeight) {
			previousDeviceOrientation = 'horizontal';
		} else {
			previousDeviceOrientation = 'vertical';
		}
		$(window).on('resize', function(event) {
			let clientWidth = document.documentElement.clientWidth; //viewport width
			let clientHeight = document.documentElement.clientHeight; //viewport height
			//console.log(`[eventAttach.js] _eventAttachMobile, on resize event, clientWidth = ${clientWidth}, clientHeight = ${clientHeight}, previousDeviceOrientation = ${previousDeviceOrientation}`);
			if (clientWidth > clientHeight && previousDeviceOrientation === 'vertical') {
				//console.log(`[eventAttach.js] _eventAttachMobile, orientation changed to horizontal`);
				tipHandlers.closeLayer(event);
				previousDeviceOrientation = 'horizontal';
			} else if (clientWidth < clientHeight && previousDeviceOrientation === 'horizontal') {
				//console.log(`[eventAttach.js] _eventAttachMobile, orientation changed to vertical`);
				tipHandlers.closeLayer(event);
				previousDeviceOrientation = 'vertical';
			}
		});
	}

}

function _eventAttachPC($targetArray) {
	//console.log(`[eventAttacher.js] _eventAttachPC`);
	$targetArray.on('mouseover', raws, $.proxy(oreHandlers.mouseover, null));
	$targetArray.on('mouseout', raws, $.proxy(oreHandlers.mouseout, null));

	let currentOptions = getCurrentOptions();
	if (currentOptions.lang["hj"] && currentOptions.lang["hj"]['zoomLayer'] && currentOptions.layerType === 'greendot') {
		$targetArray.on('mouseover', tipHook, $.proxy(tipHandlers.mouseoverHanja, null));
		$targetArray.on('mouseout', tipHook, $.proxy(tipHandlers.mouseoutHanja, null));
		$targetArray.on('click', "[data-lang='hj']", $.proxy(tipHandlers.hanjaChunkClick, null));
	} else {
		$targetArray.on('mouseover', tipHook, $.proxy(tipHandlers.mouseover, null));
		$targetArray.on('mouseout', tipHook, $.proxy(tipHandlers.mouseout, null));
		//$targetArray.on('click', tipHook, $.proxy(tipHandlers.show, null));
		$targetArray.on('click', tipHook, $.proxy(tipHandlers.mouseClick, null));
	}

	// resize 할때 닫는다.
	//$(window).resize($.proxy(tipHandlers.closeLayer, null));
	$(window).resize(function(e) {
		//console.log(`pc resize 할때 닫는다. tipHandlers.closeLayer!!---------------`);
		tipHandlers.closeLayer(e);
	});
}


export default function eventAttach($targetArray) {
	//console.log(`[eventAttacher.js] eventAttach`);
	if (getCurrentOptions()['isMobile']) {
		//console.log(`[eventAttacher.js] eventAttach, is mobile device`);
		_eventAttachMobile($targetArray);
	} else {
		//console.log(`[eventAttacher.js] eventAttach, is pc device`);
		_eventAttachPC($targetArray);
	}

	// 다른 곳을 클릭할 경우 툴팁 레이어 닫는다.
	//$(document).click($.proxy(tipHandlers.closeLayer, null));
	$(document).click(function(e) {
		if ($('#' + classMap['tipLayerId']).length > 0) {
			sendCloseNclick1();
		}
		//console.log(`다른곳을 클릭 함. tipHandlers.closeLayer!!---------------`);
		tipHandlers.closeLayer(e);
	});
};
